@import '~bootstrap/scss/bootstrap';

@import 'variables';
@import 'circle';
@import 'contactButton';
@import 'addToHomescreen';
@import 'icons';
@import 'navbar';
@import 'forms';
@import 'networkError';
@import 'pages/courses';
@import 'pages/lesson';
@import 'pages/privacy';

html,
body,
#root,
.App {
  height: 100%;
}
@function rem($px, $base: 16) {
  @return $px / $base;
}
.App {
  margin-top: -#{rem(40) + $navbar-padding-y + $navbar-padding-y};
  padding-top: #{rem(40) + $navbar-padding-y + $navbar-padding-y};
}
body {
  background-color: #fff;
}
h2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 40px;
}
.container-margin {
  margin: 20px 0;
}

.toast {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;

  &.fade {
    visibility: hidden;
    transition: $transition-fade, visibility 1s linear;

    &.show {
      visibility: visible;
    }
  }
}
/**************/
/* Home Page */
.card-header {
  color: #000;
  background: #fff;
  padding: 0;
}
.card-header .btn {
  color: #000;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card-title {
  font-size: 15px;
  text-align: left;
  margin-left: 1em;
  font-weight: 300;
  justify-content: center;
  padding-top: 0.4em;
}
.card-image {
  float: left;
}
img.card-image {
  background: #cccccc;
  border-radius: 50%;
  overflow: hidden;
}

.card-body,
.list-group-item {
  background: $body-bg;
}
.card-body {
  padding: 10px 5px 0 20px;
}
.list-group-item {
  font-size: 13px;
  color: #fff;
}
.circleIcon {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  z-index: 1;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  background-color: #cccccc;
}
.checkIcon {
  font-size: 15px;
  background: $primary;
  border-radius: 50%;
  padding: 3px;
}
.idIcon {
  font-size: 8px;
  background: #7a7a7a;
  border-radius: 50%;
  padding: 2px;
  width: 16px;
  text-align: center;
  display: inline-block;
  height: 16px;
}
.list-group-item {
  border: none;
  padding: 0.3rem 0.5rem;
}
.lesson-icon {
  float: left;
}
.home-lesson-title {
  margin-left: 0.5em;
}
.lesson-description {
  font-size: 12px;
  line-height: 15px;
  color: #838383;
}
.card {
  border-right: none;
  border-left: none;
}

/* Lesson Page */
.lesson-title-box {
  margin-top: 10px;
  text-align: center;
}
.lesson-title {
  font-size: 20px;
  margin-bottom: 0;
}
.lesson-subtitle {
  color: #7a7a7a;
  font-size: 14px;
}
.lesson-section {
  margin-bottom: 30px;
}
.lesson-section ol,
ul {
  margin: 5px 0;
  padding-left: 20px;
}
.lesson-section li {
  margin-top: 5px;
}
.section-title-hr {
  border-bottom: 0.5px solid #000;
  margin: 0;
}
.scripture-name {
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 1rem;

  p {
    margin-bottom: 0;
  }
}
.audioIcon {
  margin-top: 1rem;
  margin-left: 10px;
  margin-right: 10px;
}
.paragraph-section {
  margin-top: 5px;
}
.paragraph-item {
  margin-bottom: 1rem;
}
.doneButton {
  text-align: center;
  margin-bottom: 20px;
}

/* Language Select */
#language-select {
  svg {
    margin-inline-end: 0.5rem;
  }
  .navbar & {
    svg {
      margin-inline-end: 0;
    }
    &::after,
    &::before {
      display: none;
    }
  }
}
.language-select-container {
  .dropdown-item {
    display: flex;
  }
  .dropdown-menu a.dropdown-item.disabled {
    background-color: $gray-200;
  }
}
/* RTL Optimization */

html[dir='rtl'] {
  body {
    text-align: right;
  }

  .card-image {
    float: right;
  }
  .card-header,
  .card-title,
  .card-image,
  .card-body,
  .lesson-section,
  ol,
  ul,
  h2,
  h3,
  p {
    text-align: right;
  }
  .card-title {
    margin-right: 1em;
    margin-left: inherit;
  }
  .card-body {
    padding-right: 20px;
    padding-left: 5px;
  }
  .lesson-icon {
    float: right;
  }
  .home-lesson-title {
    margin-right: 0.5em;
  }
  .lesson-subtitle {
    text-align: center;
  }
  .lesson-section ol,
  ul {
    padding-right: 20px;
  }

  .modal-header .close {
    margin-right: auto;
    margin-left: -1rem;
  }
}

.mis-1 {
  margin-inline-start: 0.25rem !important;
}

.mis-3 {
  margin-inline-start: 0.75rem !important;
}
