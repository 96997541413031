/* Contact button */
.contact-btn {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding-top: 10px;
  font-size: 18px;
  border-radius: 25px;
}

/* RTL */
html[dir='rtl'] {
  .contact-btn {
    left: 2rem;
    right: auto;
  }
}
