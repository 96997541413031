/* Navbar */
.back-icon {
  color: #fff;
  background: none;
  border: none;
}
.back-icon:focus {
  outline: none;
}
.custom-nav {
  background: $primary;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);
}
.dropdown-submenu {
  min-width: 13rem;

  .dropdown-item,
  .dropdown-header {
    display: flex;

    .icon-start {
      min-width: 16px;
    }
    .icon-start + * {
      margin-inline-start: 1rem;
    }
  }
}
.menu-toggle {
  border: none !important;
  color: #fff !important;
  background: none !important;
  box-shadow: none !important;
}
.menu-toggle:after,
.menu-toggle:before {
  display: none !important;
}
.dropdown-item {
  margin: 5px 0;
}
.language-swich,
.reset {
  margin-left: 0.5em;
  float: left;
}
.language-swich-box {
  float: left;
  width: 20px;
}

.navbar-title {
  color: #fff !important;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 40px;

  img {
    margin-right: 1em;
  }
}
hr {
  border-top: 1px solid #000;
  margin: 0 15px;
}
.navbar-brand {
  margin-right: 0;
}
.loading {
  text-align: center;
  position: absolute;
  top: 45%;
}
.nav-btn {
  padding-top: 0;
  color: #000;
  margin-left: 1em;
}

[dir='rtl'] {
  .navbar-title {
    img {
      margin-right: auto;
      margin-left: 0.5em;
    }
  }
  .navbar-brand {
    margin-right: 0;
  }
}
