.error-container {
  text-align: center;

  h3 {
    cursor: default;
    user-select: none;
  }
}
.error-icon {
  font-size: 200px;
  text-align: center;
}
