#a2hsp {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  border: solid 1px #ccc;
  box-shadow: #ccc 0px -2px 10px 2px;
  padding: 10px;
  background-color: #fff;
  z-index: 10;

  img {
    margin: 0px 10px;
  }
  button {
    background: none;
    border: none;
    text-decoration: underline;
  }
  .add-prompt {
    font-size: 0.9rem;
  }
  .dismiss-prompt {
    float: right;
  }
}
