.lesson-block-question {
  position: relative;

  & > svg {
    position: absolute;
    left: 0;
    top: 3px;
  }

  ol {
    margin-left: 2rem !important;
  }
}
[dir='rtl'] .lesson-block-question {
  & > svg {
    left: inherit;
    right: 0;
  }

  ol {
    margin-left: inherit;
    margin-right: 2rem !important;
  }
}

.answer-background {
  background-color: #6c757d22;
  padding: 12px;
}

.lesson-block-done {
  width: 500px !important;
  display: flex;
  align-items: center;
}
