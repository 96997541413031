/* Sign up form */

.input-group-addon {
  background-color: #ffde6c;
  color: #d17d00;
}
.well-header {
  img {
    margin-bottom: 20px;
  }
  div {
    margin-bottom: 20px;
  }
  .heading-title {
    color: #fff;
  }
}
.heading-underline {
  margin-bottom: 20px;
}
.sign-btn {
  background-color: #007bff;
  color: #fff;
  margin-bottom: 20px;
}
.form-field {
  display: block !important;
  margin-bottom: 20px !important;
}
.message-box {
  height: 100px;
}
.MuiInputBase-root {
  width: 100%;
}
.form-base {
  text-align: center;
}

.form-btn {
  border-radius: 0;
}
.end-text {
  margin: 10px 0;
  color: #fff;
}
.link-text {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.login-link-text {
  color: #fff;
  border-bottom: 1px solid #fff;
  &:hover {
    color: #fff;
  }
}
.body-background,
body.signin,
body.signup {
  background-color: #1e6dad;
}
.guest-link-text {
  margin-top: 15%;
}
.top-lang {
  text-align: right;
  button {
    color: #fff !important;
  }
}
.contactTitle {
  text-align: left;
}
.contactRadio {
  margin-left: 16px !important;
}
html[dir='rtl'] {
  .contactTitle {
    text-align: right;
  }
}
.contact-toast {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 9;
}
.toast-header-icon {
  margin-right: 10px;
}

html[dir='ltr'] .Mui-error + .MuiFormGroup-root {
  border-left: solid 2px #f44336;
}
html[dir='rtl'] .Mui-error + .MuiFormGroup-root {
  border-right: solid 2px #f44336;
}

.input-group .form-control:not(:first-child) {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.input-group .input-group-text:first-child:not(:last-child),
.input-group .form-control:first-child:not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-collapse: collapse;
}
